import React from 'react';
import { object } from 'prop-types';
import loadable from '@loadable/component';
import queryString from 'query-string';

import { SkeletonHomeAdvertisingView } from 'views/home/skeleton';

import { useNavigationContext } from 'contexts/navigation-context';
import { isAuthenticated } from 'services/authentication-service';

const LazyUpdateCardView = loadable(() => import('suspended-page/loyalty-card/update'));

const UpdateLoyaltyCardPage = ({ location = {} }) => {
  const { search = '' } = location;
  const { passId = '' } = queryString.parse(search);

  const authenticated = isAuthenticated();
  const { homePageOnly = () => {} } = useNavigationContext();

  if (!authenticated) {
    homePageOnly();
    return (<SkeletonHomeAdvertisingView />);
  }

  return (<LazyUpdateCardView passId={passId} />);
};

UpdateLoyaltyCardPage.propTypes = {
  location: object,
};

UpdateLoyaltyCardPage.defaultProps = {
  location: {},
};

export default UpdateLoyaltyCardPage;
